
import { mapPlayablesToEpisodes } from '~/utils'
import { metaDataMixin, eoRendererMixin } from '~/mixins'

/**
 *  We also want to know in SSR what our default
 *  publication data might be in asyncData
 * */
const publicationData = {
  broadcastChannel: null,
  changedOn: null,
  environmentId: null,
  genre: null,
  hero: null,
  heroVideo: null,
  heroVideoStill: null,
  id: null,
  intro: null,
  model: null,
  modelId: null,
  modelName: null,
  pomsId: null,
  publishOn: null,
  seo: null,
  slug: null,
  social: null,
  subtitle: null,
  title: null,
  type: null,

  topic: null,
  episodes: [],
  content: null,
  socialMedia: null,
  callToActions: [],
  extraContent: null,
  pomsDescription: null,
  presenters: [],

  sponsors: null,
  partners: null,
  participators: null,
}


export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  layout: 'program',
  scrollToTop: false,
  async asyncData ({ $api, $config, error, params: { program: slug }}) {

    if (slug) {
      const [
        { data: { items: programs = [] } = {} },
      ] = await Promise.all([
        // Get program for this slug
        $api.programs.getOneBySlug(slug, {
          domain: $config.preprEnvironmentDomainGeneriek,
        }),
      ])


      // Check if we found a valid publication
      if (programs[0]?.id) {
        const program = programs[0]
        const publication = Object.keys(publicationData).reduce(
          (obj, key) => (
            { ...obj, [key]: program[key] }
          ),
          {},
        )

        // NOTE: It seems that the $api.programs gives a different data-structure of program episodes then
        // when we call the POMS api directly, POMS gives more data.
        const { pomsId, type } = program
        const episodes = []
        const clips = []

        if (pomsId) {
          const { data: { items: episodeItems = [] } = {} } = await $api.poms.playables.getAll({
            descendantOf: pomsId,
            offset: 0,
            limit: type == 'Webserie' ? 53 : 3,
            type: type == 'Webserie' ? 'CLIP' : 'BROADCAST',
          })

          if (episodeItems && episodeItems.length) {
            episodes.push(...mapPlayablesToEpisodes(episodeItems))
          }
        }

        // Only show clips from Televisie and Radio
        if (pomsId && (type == 'Televisie' || type == 'Radio' )) {
          const { data: { items: clipItems = [] } = {} } = await $api.poms.playables.getAll({
            descendantOf: pomsId,
            offset: 0,
            limit: 6,
            type: 'CLIP',
          })

          clips.push(...clipItems)
        }

        return {
          ...publication,
          clips,
          episodes,
        }
      } else {
        error({
          context: `Couldn't find program: ${slug}.`,
          message: '(404) Programma niet gevonden',
          severity: 'info',
          statusCode: 404,
        })

        return false
      }
    }
  },
  data () {
    return {
      ...publicationData,

      articles: [],
      articleNewsletter: null,
      hasMoreArticlesAvailable: false,
      clips: [],

      topicArticles: [],
      topicTeaser: [],
      topicPrograms: [],
    }
  },
  async fetch () {
    const { articles: pArticles = [], total = 0 } = await this.fetchProgramArticles(this.articles.length, 3)

    this.articles.push(...pArticles)
    this.hasMoreArticlesAvailable = total > this.articles?.length

    // TOPIC RELATED CONTENT //
    if (!this.topic) {
      return
    }

    const { data: { items: tArticles = [] } = {} } = await this.$api.topics.getAllNested(
      'articles',
      this.topic.id,
      {
        offset: 0,
        limit: 4,
      },
    )
    this.topicArticles = tArticles

    // Get most recent teaser for this topic
    const { data: { items: tTeasers = [] } = {} } = await this.$api.topics.getAllNested(
      'teasers',
      this.topic.id,
      {
        offset: 0,
        limit: 1,
      },
    )
    this.topicTeaser = tTeasers[0]

    const { data: { items: tPrograms = [] } = {} } = await this.$api.topics.getAllNested(
      'programs',
      this.topic.id,
      {
        offset: 0,
        limit: 4,
      },
    )
    this.topicPrograms = tPrograms

    const { data: { item: { content: tContent = [] }}} = await this.$api.topics.getOne(
      this.topic.id,
      {
        offset: 0,
        limit: 1,
      },
    )

    if (this.slug === 'the-chosen' && this.extraContent) {
      this.articleNewsletter = this.extraContent.filter((publicationElement) => {
        return publicationElement.id === "cdf51f29-69fc-4fcc-8a6f-64141880b5ba"
      })
    } else {
      this.articleNewsletter = tContent.filter((publicationElement) => {
        return publicationElement.id === "cdf51f29-69fc-4fcc-8a6f-64141880b5ba"
      })
    }
  },
  computed: {
    articlesAndEpisodes () {
      const episodes = this.type === 'Televisie' ? this.episodes : []
      const list = [
        ...this.articles,
        ...episodes,
      ]

      return list.slice(0, 6)
    },
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
    hasOnlyAboutContent () {
      const hasArticles = this.articles && this.articles.length > 0
      const hasClips = this.clips && this.clips.length > 0
      const hasEpisodes = this.episodes && this.episodes.length > 0

      return  !hasArticles && !hasClips && (!hasEpisodes || this.type == "Radio")
    },
  },
  methods: {
    async fetchProgramArticles (offet = 0, limit = 6) {
      const { data: { items: pArticles = [], meta: { total = 0 } = {} } = {} } = await this.$api.programs.getAllNested(
        'articles',
        this.id,
        {
          offset: offet,
          limit: limit,
        },
      )

      return {
        articles: pArticles,
        total,
      }
    },
  },
}
